import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { ApiService } from '../api/api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

interface Role {
  name: string;
  permissions: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private rolesSubject = new BehaviorSubject<Role[]>([]);
  private currentRoleSubject = new BehaviorSubject<string | null>(null); // Current role
  private baseUrl = environment.apiUrl;
  // private baseUrl = 'https://mobile-gateway.dev-api.knostos.com/api/v1';
  private headers = new HttpHeaders({
    App_id: 'nlSwUjIBL3QpB8++8OWCog==',
  });

  constructor(private http: HttpClient) {
    const savedRoles = localStorage.getItem('userRoles');
    const savedRole = localStorage.getItem('currentRole');

    if (savedRoles) {
      this.rolesSubject.next(JSON.parse(savedRoles));
    }

    if (savedRole) {
      this.currentRoleSubject.next(savedRole);
    }
  }

  setRoles(roles: Role[]): void {
    this.rolesSubject.next(roles);
    localStorage.setItem('userRoles', JSON.stringify(roles));
  }

  getRoles(): Role[] {
    return this.rolesSubject.value;
  }

  setCurrentRole(role: string): void {
    this.currentRoleSubject.next(role);
    localStorage.setItem('currentRole', role);
  }

  isLoggedin(): Observable<boolean> {
    return this.validateSession();
  }

  getCurrentRole(): string | null {
    return this.isLoggedin() ? this.currentRoleSubject.value : null;
  }

  /**
   * Resets the AuthService state completely
   */
  reset(): void {
    this.rolesSubject.next([]);
    this.currentRoleSubject.next(null);
    localStorage.removeItem('userRoles');
    localStorage.removeItem('currentRole');
  }

  /**
   * Logs the user out and resets the application state.
   * Returns an observable to notify when logout is completed.
   */
  logout(): Observable<void> {
    this.reset(); // Clear local state
    return this.http
      .post<void>(
        `${this.baseUrl}/logout`,
        {},
        { headers: this.headers, withCredentials: true }
      )
      .pipe(
        map(() => {
          console.log('Successfully logged out');
        }),
        catchError((error) => {
          console.error('Error during logout:', error);
          return of(); // Gracefully handle errors
        })
      );
  }

  /**
   * Validates the session with the server.
   * Returns true if session is valid, false otherwise.
   */
  validateSession(): Observable<boolean> {
    return this.http
      .get(`${this.baseUrl}/check_session`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        map(() => true),
        catchError(() => of(false)) // Treat errors as invalid session
      );
  }
}
