import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root', // Makes it available app-wide
})
export class StateService {
  private notificationsCountSubject = new BehaviorSubject<number>(0);
  notificationsCount$ = this.notificationsCountSubject.asObservable();

  private userInfoSubject = new BehaviorSubject<{
    username: string;
    email: string;
    avatar: string;
  } | null>(null);

  userInfo$ = this.userInfoSubject.asObservable();
  private readonly userKey = 'userInfo';

  // Update the notifications count
  setNotificationsCount(count: number): void {
    this.notificationsCountSubject.next(count);
  }

  // Get the current notifications count
  getNotificationsCount(): number {
    return this.notificationsCountSubject.getValue();
  }

  // Save user info and persist it in localStorage
  setUserInfo(
    userInfo: { username: string; email: string; avatar: string } | null
  ): void {
    console.log(userInfo?.email);
    this.userInfoSubject.next(userInfo);

    if (userInfo) {
      console.log(userInfo);
      localStorage.setItem(this.userKey, JSON.stringify(userInfo));
    } else {
      localStorage.removeItem(this.userKey);
    }
  }

  // Retrieve user info from BehaviorSubject
  getUserInfo(): { username: string; email: string; avatar: string } | null {
    return this.userInfoSubject.getValue();
  }

  // Retrieve user info from localStorage
  private getStoredUserInfo(): { username: string; email: string } | null {
    const storedUser = localStorage.getItem(this.userKey);
    return storedUser ? JSON.parse(storedUser) : null;
  }
}
