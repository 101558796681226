import { Component } from '@angular/core';
import { IonApp, IonRouterOutlet } from '@ionic/angular/standalone';
import { ApiService } from './Services/api/api.service';
import { register } from 'swiper/element/bundle';

register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {
  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.initializeBranding();
  }

  initializeBranding() {
    this.apiService.initializeBrandingLabels().subscribe({
      next: (response: any) => {
        this.applyBranding(response.settings);
      },
      error: (error) => {
        console.error('Failed to initialize branding:', error);
      },
    });
  }

  applyBranding(branding: any) {
    if (branding.primaryBrandColor) {
      document.documentElement.style.setProperty(
        '--primary-brand-color',
        branding.primaryBrandColor
      );
    }
    if (branding.secondaryBrandColor) {
      document.documentElement.style.setProperty(
        '--secondary-brand-color',
        branding.secondaryBrandColor
      );
    }

    if (branding.logoUrl) {
      document.documentElement.style.setProperty(
        '--logo-url',
        `url('${branding.logoUrl}')`
      );
    }
  }
}
