import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { StateService } from '../state/state.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  private headers = new HttpHeaders({
    App_id: 'nlSwUjIBL3QpB8++8OWCog==',
  });

  constructor(private http: HttpClient, private stateService: StateService) {}

  initializeBrandingLabels() {
    let brandHeaders = new HttpHeaders({
      App_id: 'nlSwUjIBL3QpB8++8OWCog==',
    });

    return this.http.get(`${this.baseUrl}/tenant/settings`, {
      headers: brandHeaders,
      withCredentials: true,
    });
  }

  getNotifications(): Observable<any[]> {
    return this.http
      .get<any[]>(`${this.baseUrl}/user/notifications`, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(
        tap((notifications) => {
          this.stateService.setNotificationsCount(notifications.length);
        })
      );
  }

  getBlockerCards(role: string): Observable<any[]> {
    console.log(role);
    const params = { role };
    return this.http.get<any[]>(`${this.baseUrl}/blockers`, {
      headers: this.headers,
      params,
      withCredentials: true,
    });
  }

  getOrders(role: string) {
    const params = { role };
    return this.http.get(`${this.baseUrl}/orders`, {
      headers: this.headers,
      params,
      withCredentials: true,
    });
  }

  getPatients() {
    return this.http.get(`${this.baseUrl}/patient`, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  getPatientById(id: string) {
    return this.http.get(`${this.baseUrl}/patient/${id}`, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  getPatientsMedications_sultanAPI(id: string) {
    return this.http.get(`${this.baseUrl}/patient/${id}/medications`, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  getPatientPrescriptions(id: string) {
    return this.http.get(`${this.baseUrl}/patient/${id}/prescriptions`, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  getOrderPrescriptions(id: string) {
    return this.http.get(`${this.baseUrl}/orders/${id}/prescriptions`, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  resolveBlocker(
    blockerId: any,
    activityId: any,
    type: string,
    value: string
  ): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/blockers/resolve`,
      {
        blocker_id: blockerId,
        activity_id: activityId,
        type: type,
        value: value,
      },
      {
        headers: this.headers,
        withCredentials: true,
      }
    );
  }

  resolveFormBlocker(
    blockerId: any,
    activityId: any,
    type: string,
    form_data: string
  ): Observable<any> {
    return this.http.put(
      `${this.baseUrl}/blockers/resolve`,
      {
        blocker_id: blockerId,
        activity_id: activityId,
        type: type,
        form_data: form_data,
      },
      {
        headers: this.headers,
        withCredentials: true,
      }
    );
  }

  getDocuments() {
    return this.http.get(`${this.baseUrl}/knostos-documents`, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  reviewDocument(id: string, reviewResult: string) {
    return this.http.put(
      `${this.baseUrl}/knostos-documents/${id}/review`,
      {
        review_result: reviewResult,
        input_data: '',
      },
      {
        headers: this.headers,
        withCredentials: true,
      }
    );
  }

  resolveAttachment(
    blockerId: string,
    activityId: string,
    file: File
  ): Observable<any> {
    return new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = () => {
        const base64File = reader.result?.toString().split(',')[1]; // Extract Base64 string
        if (!base64File) {
          observer.error('File conversion failed.');
          return;
        }

        // Perform the HTTP PUT request
        this.http
          .put(
            `${this.baseUrl}/blockers/resolve`,
            {
              blocker_id: blockerId,
              activity_id: activityId,
              type: 'UPLOAD_ATTACHMENT',
              file: base64File,
              file_name: file.name,
            },
            {
              headers: this.headers,
              withCredentials: true,
            }
          )
          .subscribe({
            next: (response) => {
              observer.next(response); // Notify the subscriber of the response
              observer.complete(); // Complete the Observable
            },
            error: (error) => {
              observer.error(error); // Pass the error to the subscriber
            },
          });
      };

      reader.onerror = (error) => {
        observer.error('Error reading file: ' + error); // Notify the subscriber of the error
      };

      // Read the file as Base64
      reader.readAsDataURL(file);
    });
  }
}
